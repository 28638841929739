import { useEffect, useState } from 'react'

import useWindowSizeHook from '../../hooks/useWindowSize'

import './index.css'

function Segment (props) {
  const { index, settings } = props
  const {
    fill,
    angle = 0,
  } = settings

  const [ width, setWidth ] = useState(1)
  const [ height, setHeight ] = useState(1)
  const size = useWindowSizeHook()

  useEffect(() => {
    // Use the width of the window to set the size of the segments
    // Prevents them being over-sized so animations are consitent-ish
    const width = size.width * 1.3
    setWidth(Math.round(width))
    setHeight(Math.round(width / 2))
  }, [size])


  return <>
    <svg
      className="segment"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 500"
      width={ width || 0 }
      height={ height || 0 }
      style={{
        transform: `translate(-100%) rotate(${angle}deg)`,
        zIndex: 10 + index,
      }}
    >
      <path
        className="segmentBackground"
        d="M1000,500,135.3,0A996.63,996.63,0,0,0,0,499.78Z"
        fill={ fill }
        style={{
          animationDelay: `${(index * 200) + 300}ms`
        }}
      />
    </svg>
  </>
}

export default Segment
