// import React from 'react'

import './index.css'

function Label (props) {
  const { angle, index = 0 } = props

  return <>
    <svg id="label-06"
      className="label"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 741 91.14"
      width="741"
      height="91.14"
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        transformOrigin: 'center right',
        transform: `translateY(50%) rotate(${angle}deg)`,
        animationDelay: `${1700 + (index * 200)}ms`,
      }}
    >
      <g>
        <path d="M98.7,83.18a17.36,17.36,0,0,1-5.31-.8A6.17,6.17,0,0,0,91.86,82a7.13,7.13,0,0,0-1.8.49,2.92,2.92,0,0,1-.93.15,3,3,0,0,1-2.29-1.41,7.94,7.94,0,0,1-1.76-5,4.54,4.54,0,0,1,.93-3,3,3,0,0,1,2.46-1.14,4.06,4.06,0,0,1,2.45.9,18.55,18.55,0,0,1,1.78,2A6.92,6.92,0,0,0,98.14,78a5.38,5.38,0,0,0,4.43-2,8.51,8.51,0,0,0,1.65-5.5,14.56,14.56,0,0,0-2.62-8.75,7.74,7.74,0,0,0-2.37-2.09,5.46,5.46,0,0,0-2.73-.78,2.42,2.42,0,0,0-1.83.74,2.61,2.61,0,0,0-.72,1.9,1.67,1.67,0,0,0,.6,1.46,1.06,1.06,0,0,0,.44.2,9.49,9.49,0,0,0,1.23,0,2.1,2.1,0,0,1,1.54.59,2,2,0,0,1,.61,1.49c0,1.19-.78,2-2.36,2.53a19.81,19.81,0,0,1-6,.82,21.24,21.24,0,0,1-6.1-.75c-1.57-.49-2.35-1.37-2.35-2.64a1.9,1.9,0,0,1,.54-1.35,2.21,2.21,0,0,1,1.4-.67,1.79,1.79,0,0,0,1-.34,2.76,2.76,0,0,0,.47-1.1A11.19,11.19,0,0,1,88.55,56,13.69,13.69,0,0,1,93,53.55a17.25,17.25,0,0,1,5.49-.88,17.65,17.65,0,0,1,8.64,2.17A15.08,15.08,0,0,1,115,68.18a14.11,14.11,0,0,1-2.1,7.39,15.06,15.06,0,0,1-6.25,5.81A17.25,17.25,0,0,1,98.7,83.18Z"/>
        <path d="M67,74.67a11.89,11.89,0,0,1-6.9-2,10.87,10.87,0,0,1-3.41-3.8,10.54,10.54,0,0,1-1.34-5.11q0-4.66,4.28-7.93A15,15,0,0,1,69,52.67a11.79,11.79,0,0,1,8.31,2.93A9.56,9.56,0,0,1,80.41,63a10.4,10.4,0,0,1-3.78,8.29A14.23,14.23,0,0,1,67,74.67Zm1.72-5a1.84,1.84,0,0,0,1.51-.7,2.83,2.83,0,0,0,.57-1.86,17.65,17.65,0,0,0-.48-3.51A26.65,26.65,0,0,0,69.2,59.7,2.29,2.29,0,0,0,67,57.94c-1.28,0-1.91.81-1.91,2.42a20.3,20.3,0,0,0,.48,4.11,16.75,16.75,0,0,0,1.17,3.74A2.27,2.27,0,0,0,68.75,69.71Z"/>
        <path d="M40.54,74.67a11.89,11.89,0,0,1-6.9-2,11,11,0,0,1-3.41-3.8,10.53,10.53,0,0,1-1.33-5.11q0-4.66,4.27-7.93a15,15,0,0,1,9.35-3.12,11.79,11.79,0,0,1,8.31,2.93A9.56,9.56,0,0,1,53.92,63a10.4,10.4,0,0,1-3.78,8.29A14.23,14.23,0,0,1,40.54,74.67Zm1.72-5a1.84,1.84,0,0,0,1.51-.7,2.83,2.83,0,0,0,.57-1.86,17.65,17.65,0,0,0-.48-3.51,26.65,26.65,0,0,0-1.15-3.94,2.28,2.28,0,0,0-2.17-1.76c-1.27,0-1.91.81-1.91,2.42a20.3,20.3,0,0,0,.48,4.11,16.75,16.75,0,0,0,1.17,3.74A2.27,2.27,0,0,0,42.26,69.71Z"/>
        <path d="M2.23,80.86V61.25a9.39,9.39,0,0,0-.14-2.15,1.82,1.82,0,0,0-.78-.93,4,4,0,0,1-1-.94,1.74,1.74,0,0,1-.27-1q0-1.73,3.46-2.62a25.11,25.11,0,0,1,6.23-.8,1.28,1.28,0,0,1,.89.21,1.65,1.65,0,0,1,.27,1c.07.73.33,1.09.77,1.09a2.21,2.21,0,0,0,1.14-.64,7.55,7.55,0,0,1,4.88-1.79,8.6,8.6,0,0,1,6,2.47,12.19,12.19,0,0,1,2.54,3.42,11,11,0,0,1,1.29,5.09,11.22,11.22,0,0,1-1.68,5.84,10.39,10.39,0,0,1-4.21,4,9.55,9.55,0,0,1-4.4,1.16,8.83,8.83,0,0,1-4.47-1.12,1.28,1.28,0,0,0-.52-.17c-.46,0-.69.62-.69,1.85a1.75,1.75,0,0,0,.38,1.21,3,3,0,0,0,1.34.72c.91.29,1.36.79,1.36,1.51,0,1-1,1.86-3.12,2.45a35.23,35.23,0,0,1-7.37,1.52C2.85,82.62,2.23,82,2.23,80.86ZM13.9,70.05a2.46,2.46,0,0,0,2.21-1.48,10.71,10.71,0,0,0,1.1-5.2A7.6,7.6,0,0,0,16.16,59a2.38,2.38,0,0,0-2-1.08,2.15,2.15,0,0,0-2,1.63,15.65,15.65,0,0,0-.63,5.23c0,2.2.25,3.67.75,4.4A2,2,0,0,0,13.9,70.05Z"/>
        <path d="M108.75,31.26V24a3.37,3.37,0,0,0-.63-2.11,2,2,0,0,0-1.63-.82,4.92,4.92,0,0,0-2.21.78,1.21,1.21,0,0,1-.64.19,1.2,1.2,0,0,1-1-.62,2.41,2.41,0,0,1-.43-1.36,3.41,3.41,0,0,1,1.46-2.55,11,11,0,0,1,7.39-2.6,7.78,7.78,0,0,1,5.52,1.94A6.7,6.7,0,0,1,118.72,22V31.9c0,.33,0,.54.12.61a1,1,0,0,0,.61.12h2.21a1.06,1.06,0,0,1,.8.21,1.7,1.7,0,0,1,.17.95v1a1.08,1.08,0,0,1-.5.94l-9.84,6.19a1.87,1.87,0,0,1-.9.19h-1.74a.86.86,0,0,1-.72-.24,1.83,1.83,0,0,1-.18-1v-2.9a1.24,1.24,0,0,0-.2-.84,1.35,1.35,0,0,0-.87-.19h-4a1.34,1.34,0,0,1-1-.29,2.16,2.16,0,0,1-.24-1.24V34.11a2.45,2.45,0,0,1,.3-1.42,1.31,1.31,0,0,1,1.1-.4h3.89a.84.84,0,0,0,1-1Z"/>
        <path d="M89.2,35.4V22.92a6.38,6.38,0,0,0-.27-2.17,2.94,2.94,0,0,0-1.08-1.27,3.49,3.49,0,0,1-.89-.8,1.42,1.42,0,0,1-.23-.85q0-2.77,7-2.77a23.84,23.84,0,0,1,6,.6,2.41,2.41,0,0,1,1.21.75,1.83,1.83,0,0,1,.46,1.23,1.5,1.5,0,0,1-.23.9,6.6,6.6,0,0,1-1.21,1A2.8,2.8,0,0,0,99,21.18a14.59,14.59,0,0,0-.28,3.33c0,.47,0,1.36.1,2.66s.11,2,.14,2.26a1.75,1.75,0,0,0,1.2,1.35,1.86,1.86,0,0,1,1.33,1.72c0,.91-.58,1.58-1.74,2a38.56,38.56,0,0,1-5.16,1.57,20.14,20.14,0,0,1-4.25.65C89.59,36.74,89.2,36.29,89.2,35.4Zm4.25,9.41a5.52,5.52,0,0,1-3-.69,2.16,2.16,0,0,1-1.1-1.88,2.94,2.94,0,0,1,1.66-2.63,12.44,12.44,0,0,1,6.33-1.52,4.56,4.56,0,0,1,2.5.6,2.41,2.41,0,0,1,1.24,2.15c0,1.23-.88,2.2-2.64,2.9A13.51,13.51,0,0,1,93.45,44.81Z"/>
        <path d="M75,36.74c-.81,0-1.22-.58-1.22-1.74v-.37c0-.23,0-.42,0-.56,0-.77-.23-1.16-.69-1.16q-.36,0-1.14.93A8.23,8.23,0,0,1,69.09,36a8.84,8.84,0,0,1-3.61.79q-3.59,0-5-2.63c-.36-.64-.65-1-.88-1s-.53.28-1.12.86a8.82,8.82,0,0,1-6.34,2.73,6.66,6.66,0,0,1-4.53-1.53,6,6,0,0,1-1.85-4.77c0-.46,0-1.23,0-2.32,0-.37,0-1.5,0-3.37,0-1.21,0-2.05,0-2.54,0-.3,0-.51,0-.64a3.67,3.67,0,0,0-.86-2.41,9.26,9.26,0,0,1-.63-.82,1.09,1.09,0,0,1-.12-.55C44.3,16,46.46,15,50.77,15a12.83,12.83,0,0,1,4.61.69q1.74.68,1.74,1.8a1.48,1.48,0,0,1-.11.66c-.08.15-.36.54-.83,1.19s-.67,2.54-.67,5.86a13.62,13.62,0,0,0,.41,4,1.85,1.85,0,0,0,1.89,1.35,1.92,1.92,0,0,0,1.26-.44,2.09,2.09,0,0,0,.72-1.1A24.91,24.91,0,0,0,60,25a35.07,35.07,0,0,0-.22-4.38,6.89,6.89,0,0,0-1.09-2.15,1.46,1.46,0,0,1-.21-.81q0-2.57,6.68-2.56a15.94,15.94,0,0,1,5.41.6,1.68,1.68,0,0,1,1.12,1.68,1.53,1.53,0,0,1-.14.75,9.16,9.16,0,0,1-1,1,2.31,2.31,0,0,0-.62.85,6.13,6.13,0,0,0-.2,1.45c-.1,1.57-.15,3.16-.15,4.76,0,2.81.71,4.22,2.15,4.22,1.07,0,1.71-.61,1.91-1.81A34.74,34.74,0,0,0,74,23.7a28.42,28.42,0,0,0-.1-3.1,4.26,4.26,0,0,0-.53-1.52,3.38,3.38,0,0,1-.51-1.51c0-.93.65-1.57,2-1.93a17.67,17.67,0,0,1,4.69-.58,16,16,0,0,1,4.85.53c1,.35,1.55.9,1.55,1.66a1.23,1.23,0,0,1-.21.73,5.8,5.8,0,0,1-.93.95,3.61,3.61,0,0,0-1.12,2.42c-.15,1.78-.23,3.62-.23,5.53a13.12,13.12,0,0,0,.2,2.84,2.3,2.3,0,0,0,.87,1.3,4.46,4.46,0,0,1,.83.75,1.22,1.22,0,0,1,.18.71c0,1-.75,1.72-2.26,2.28A27.86,27.86,0,0,1,75,36.74Z"/>
        <path d="M23.18,25.11h10.1c.44,0,.67-.19.67-.58a3.47,3.47,0,0,0-1.52-2.63,5.06,5.06,0,0,0-3.25-1.23,7.8,7.8,0,0,0-2.29.39,7.6,7.6,0,0,0-2.08,1,2.28,2.28,0,0,1-1.16.52,1.46,1.46,0,0,1-1.09-.69A2.28,2.28,0,0,1,22,20.45a3.9,3.9,0,0,1,1.94-3,12.74,12.74,0,0,1,7.88-2.56,11.17,11.17,0,0,1,7.56,2.62,10.72,10.72,0,0,1,2.45,3.11,10.87,10.87,0,0,1,1.27,5.12,10.46,10.46,0,0,1-2.19,6.49A11.47,11.47,0,0,1,36.11,36a12,12,0,0,1-4.74.93,10.69,10.69,0,0,1-6.94-2.32,9.4,9.4,0,0,1-2.32-2.75,7.15,7.15,0,0,1-1-3.53A4.12,4.12,0,0,1,21.68,26,1.74,1.74,0,0,1,23.18,25.11Zm9.54,4H30.55c-.92,0-1.37.42-1.37,1.27a2.72,2.72,0,0,0,.66,1.89,2.25,2.25,0,0,0,1.74.73,2.22,2.22,0,0,0,1.89-1,3,3,0,0,0,.65-1.78,1.13,1.13,0,0,0-.31-.9A1.76,1.76,0,0,0,32.72,29.13Z"/>
        <path d="M11.11,36.89a20.43,20.43,0,0,1-3.5-.48,5.87,5.87,0,0,0-.82-.08,5.51,5.51,0,0,0-1.42.38,2.4,2.4,0,0,1-.84.2,2.74,2.74,0,0,1-2-1.27A9.38,9.38,0,0,1,1.2,33.43a5.5,5.5,0,0,1-.51-2.09,1.86,1.86,0,0,1,.6-1.42,2.09,2.09,0,0,1,1.5-.57,2.57,2.57,0,0,1,1.35.36A14.73,14.73,0,0,1,6,31.32a3.73,3.73,0,0,0,2.28,1.33,1.41,1.41,0,0,0,1-.44,1.43,1.43,0,0,0,.44-1c0-.7-.68-1.36-2-2a18.73,18.73,0,0,1-5.57-3.57,5.47,5.47,0,0,1-1.63-4,6.06,6.06,0,0,1,2.62-5,8.79,8.79,0,0,1,5.52-1.74,28,28,0,0,1,3,.28c1.39.16,2.17.23,2.35.23a4,4,0,0,0,.64-.06,7.31,7.31,0,0,1,.9-.09,2.3,2.3,0,0,1,1.59.52,7.25,7.25,0,0,1,1.63,2.5,7.42,7.42,0,0,1,.76,2.93c0,1.32-.52,2-1.55,2A1.74,1.74,0,0,1,17,22.8a27.52,27.52,0,0,1-2.11-2.11,7.83,7.83,0,0,0-1.46-1.3A2.35,2.35,0,0,0,12.16,19a1.69,1.69,0,0,0-1.14.38,1.28,1.28,0,0,0-.43,1c0,.64.55,1.23,1.66,1.76a15.54,15.54,0,0,1,5,3.5,6.22,6.22,0,0,1,1.54,4.17,6.61,6.61,0,0,1-1.57,4.4A7.54,7.54,0,0,1,11.11,36.89Z"/>
      </g>
    </svg>

  </>
}

export default Label
