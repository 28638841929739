// import React from 'react'

import './index.css'

function Needle () {
  return <>
    <div id="needleWrapper">
      <img id="needleImage"
        src="./needle@2x.png"
        alt=""
        role="presentation"
      />
    </div>
  </>
}

export default Needle
