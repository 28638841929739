// import React from 'react'

import './index.css'

function SemiCircle (props) {

  return <>
    <svg
      id="semiCircle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 500"
      width="1000"
      height="500"
      style={{
        position: 'relative',
        bottom: 0,
        opacity: 0.1,
        width: '95vw',
        maxWidth: '900px',
        height: 'auto',
        zIndex: 100000,
      }}
    >
      <path
        d="M500,0C223.86,0,0,223.86,0,500H1000C1000,223.86,776.14,0,500,0Z"
        fill="#ffffff"
      />
    </svg>
  </>
}

export default SemiCircle
